:root {
    --background-color: #1e1e1e; 
    --font-color: #000000; 
    --form-bg-color: rgba(255, 255, 255, 0.8); 
    --input-bg-color: #f7f7f7; 
    --input-border-color: #202424;
    --submit-bg-color: #00ffff; 
    --submit-font-color: #000000;
    --hover-glow-color: #000000;
    --hover-glow-font-color: #00ffff;
    --transition-speed: 0.3s; 
    --p-font-color: #000000;
    --info-text-font-color: #000000;
    --infobox-color: rgba(30, 30, 30, 0.9);
}

.form-body {
    /* background: url('./background.avif') no-repeat center center fixed; */
    background-size: cover;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
}

.form-body h2,.header-register {
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 20px;
    font-size: 28px;
    /* border-bottom: 2px solid black; */
    margin-bottom: 20px;
    text-transform: uppercase;
    animation: fadeIn 1s ease-in-out;
}

form {
    
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 25px;
    border-radius: 15px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 15px rgba(0, 255, 255, 0.2);
    animation: slideIn 0.5s ease-in-out;
    border: 2px solid var(--input-border-color); /* Optional: Border to outline the form */
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    -webkit-backdrop-filter: blur(10px); /* Blur effect for Safari */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

input[type="text"], input[type="email"], input[type="phone"], select {
    width: 95%;
    padding: 12px;
    font-size: 16px;
    background-color: var(--input-bg-color);
    border: 2px solid var(--input-border-color);
    border-radius: 5px;
    /* color: var(--background-color); */
    color: black;
    transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
}
select{
    width: 100% !important; 
    color: black;
}
.Email-text{
    color: #ffffff;
    text-align: center;
}
input[type="text"]:focus, input[type="email"]:focus, input[type="phone"]:focus, select:focus {
    border-color: var(--hover-glow-color);
    box-shadow: 0px 0px 8px var(--hover-glow-color);
}

#submit {
    padding: 15px;
    text-align: center;
    font-size: 18px;
    width: 100%;
    border-radius: 5px;
    border: 2px solid var(--input-border-color);
    border-radius: 30px;
    background-color: rgb(164, 164, 164);
    color: black;
    cursor: pointer;
    text-transform: uppercase;
    transition: all var(--transition-speed) ease;
    
}

#submit:hover {
    opacity: 0.9;
    background-color: rgb(116, 111, 111);
    color: rgb(255, 255, 255);
    /* box-shadow: 0px 0px 8px var(--hover-glow-color); */
}

@media (max-width: 768px) {
    .form-body {
        /* padding: 10px; */
        align-items: center;
        justify-content: center;
    }

    form {
        padding: 15px;  
        max-width: 90%; 
        animation: none;  
    }

    h2, .header-register {
        font-size: 20px;  
        /* padding: 10px;   */
    }

    .form-group {
        margin-bottom: 15px;  
    }

    label {
        font-size: 14px;  
        margin-bottom: 5px;
    }

    input[type="text"], input[type="email"], input[type="phone"], select {
        padding: 8px;  
        font-size: 14px;  
        width: 95%;  
    }
    select{
        width: 100% !important; 
    }
    #submit {
        padding: 12px; 
        font-size: 16px;  
    }

    .modal-content {
        padding: 15px;  
        max-width: 90%; 
    }

    .close {
        font-size: 24px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}