:root {
  --font-color: #e0e2e4;
  --background-color:#ffffff; 
  --form-bg-color: rgba(51, 50, 50, 0.8); 
  --p-font-color:#e0e2e4;
  --info-text-font-color: #c6bcb6;
  --submit-bg-color: #00ffff; 
  --submit-font-color: #000000; 
  --hover-glow-color: #a3caca; 
  --hover-glow-font-color: #00ffff;
  --infobox-color:rgba(30, 30, 30, 0.9);
  --transition-speed: 0.3s; 
}

.thankyou-body {
  background-size: cover;
  /* background-color: var(--background-color); */
  padding:  20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 90vh;
  margin-left: 30%;
}

.container-thankyou {
  background-color: var(--form-bg-color);
  padding: 25px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 255, 255, 0.2);
  animation: slideIn 0.5s ease-in-out;
  border: 2px solid var(--input-border-color);
  text-align: center;
}

h2 {
  /* color: var(--font-color);
  padding-bottom: 20px;
  font-size: 32px;
  border-bottom: 2px solid var(--font-color);
  margin-bottom: 20px;
  text-transform: uppercase; */
  animation: fadeIn 1s ease-in-out;
}

.con-text ,.info-text{
  font-size: 18px;
  margin-bottom: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.con-text{
  color: var(--p-font-color);
}
.info-text{
  color: var(--info-text-font-color);
}
.info-box {
  background-color: var(--infobox-color);
  padding: 20px;
  border-radius: 10px;
  animation: fadeIn 1s ease-in-out;
}

h3 {
  color: var(--font-color);
  margin-bottom: 15px;
  font-size: 24px;
}

.join-link {
  display: inline-block;
  padding: 12px 20px;
  background-color: var(--submit-bg-color);
  color: var(--submit-font-color);
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: all var(--transition-speed) ease;
}

.join-link:hover {
  opacity: 0.9;
  background-color: var(--background-color);
  color: var(--hover-glow-font-color);
  box-shadow: 0px 0px 8px var(--hover-glow-color);
}

@media (max-width: 768px) {
  .thankyou-body {
    padding: 10px; 
    margin-left: 0%;
  }

  .container-thankyou {
    padding: 15px;  
    max-width: 90%; 
    animation: none; 
  }

  h2 {
    font-size: 24px;  

  }

  .con-text, .info-text {
    font-size: 14px; 
   
  }

  .info-box {
    padding: 15px;  
  }

  h3 {
    font-size: 20px; 
    margin-bottom: 10px;  
  }

  .join-link {
    padding: 10px 15px; 
    font-size: 14px;  
  }
}


@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(-20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}