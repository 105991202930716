.logo-hunt-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top:-70px;
    
  }
  
  .logo-hunt-page h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    /* color: #333; */
  }
  
  .logo-hunt-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .logo-hunt-image {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
  }
  
  .logo-hunt-image img {
    width: 90%;
    max-width: 400px;
    height: auto;
    margin-top:20px;
    border-radius: 8px;
  }
  
  .logo-hunt-details {
    flex: 2;
    min-width: 300px;
    max-width: 600px;
  }
  
  .logo-hunt-details h2,
  .logo-hunt-details h3 {
    /* color: #555; */
    margin-top: 10px;
    font-size: 25px;
  }
  
  .logo-hunt-details p,
  .logo-hunt-details ul {
    margin: 5px 0;
    line-height: 1.6;
    font-size: 18px;
    /* color: #333; */
  }
  
  .logo-hunt-details ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  .logo3{
    color:#d148d3;
    font-weight: 450;
  }
  @media screen and (max-width: 768px) {
    .logo-hunt-content {
      flex-direction: column;
      align-items: center;
    }
    .logo-hunt-image img {
        width: 100%; 
      }
  }
  
  @media screen and (max-width: 480px) {
    .logo-hunt-page {
      padding: 10px;
    }
  
    .logo-hunt-details h2,
    .logo-hunt-details h3 {
      font-size: 25px;
    }
    
    .logo-hunt-image img {
        width: 100%; 
        margin-top:0px;
        
      }
      .logo-hunt-page h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
        /* color: #333; */
      }
    .logo-hunt-details p,
    .logo-hunt-details ul {
      font-size: 18px;
    }
   
  }
  