/* Container styles for responsive layout */
.sponsor-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin: 0 auto; /* Center the container */
}

/* Sponsor card styles with glassmorphism effect */
.sponsor-card {
  width: 200px;
  height: 450px;
  padding: 20px;
  margin: 0px 10px;

  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Blur effect for Safari */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: transform 0.3s ease;
}

/* Image styles within the card */
.sponsor-image {
  /* width: 150px; */
  margin-bottom: 15px;
}


/* Title and description styles */
.sponsor-title {
  font-size: 1.1em;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.sponsor-description {
  font-size: 1em;
  opacity: 0.8;
}
.sponsor-description a{
  color: #1c8fdb;
}
@media (max-width: 1100px) {
  .sponsor-container {
    flex-direction: column;
    align-items: center;
  }
  .sponsor-card {
    width: 230px;
  }
  .sponsor-title {
    font-size: 1.3em;
  }
  .sponsor-description {
    font-size: 0.8em;
  }
}
@media (max-width: 768px) {
  .sponsor-container {
    flex-direction: column;
    align-items: center;
  }
  .sponsor-card {
    /* width:00px; */
    margin: 10px auto;
  }
}
@media (max-width: 550) {
}
